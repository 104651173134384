import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import "./promo.css";

function Promotion() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [notifyItem, setNotifyItem] = useState();
    const [isShow, setShow] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);
    return (
        <>
            <div className="banner-game">
                <img src={require("../../image/promo.png")} />
            </div>
            <section className="promo-section">
                <div className="container">
                    <div className="promo-title">Thông báo mới nhất</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: "30px" }}>
                        {notify.map((item, index) => (
                            <>
                                {index > 1 && item.title !== "marquee" && item.isShow === true ? (
                                    <div
                                        className="box-promo"
                                        onClick={() => {
                                            if (item.title.indexOf("{") !== 0) {
                                                setNotifyItem(item);
                                                setShow(true);
                                            } else {
                                                navigate(item.content.replace(/<\/?[^>]+(>|$)/g, ""));
                                            }
                                        }}>
                                        <div className="box-promo-img">
                                            <img alt="" src={item.image} />
                                        </div>
                                        <div className="box-promo-title">{item.title.replace("}", "").replace("{", "")}</div>
                                    </div>
                                ) : null}
                            </>
                        ))}
                    </div>
                </div>
            </section>
            {notifyItem != null && isShow ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-header">{notifyItem.title}</div>
                        <div className="popup-content">
                            {notifyItem.content.indexOf("{") != -1 && notifyItem.content.indexOf("{") != -1 ? (
                                <img
                                    src={notifyItem.content.substring(notifyItem.content.indexOf("{") + 1, notifyItem.content.indexOf("}"))}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            ) : (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: notifyItem.content
                                    }}
                                />
                            )}
                        </div>
                        <button onClick={() => setShow(false)} className="popup-close">
                            Đóng
                        </button>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default Promotion;
