import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

function Transfer({ profile, getProfile }) {
    const [bank, setBank] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [payment, setPayment] = useState([]);
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [type, setType] = useState(1);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeOption, setActiveOption] = useState(1);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            setLoad(true);
            const formData = {
                provider: sanh,
                type: activeOption,
                amount: Number(data.money.replaceAll(".", "").replaceAll(",", ""))
            };
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (res.data.data.errMsg == "SUCCESS") {
                        if (type == "1") {
                            swal("Thành công", `Chuyển quỹ ra khỏi sảnh ${sanh} thành công!`, "success").then(() => window.location.reload());
                        } else {
                            swal("Thành công", `Chuyển quỹ vào sảnh ${sanh} thành công!`, "success").then(() => window.location.reload());
                        }
                        axios.post(`${process.env.REACT_APP_API_URL}/casino/balance`, { provider: sanh }).then((res) => {
                            if (res.data.data.errMsg == "SUCCESS") {
                                setBalance(res.data.data.balance);
                            } else {
                                window.location.reload();
                            }
                        });
                        setLoad(false);
                    } else {
                        swal("Đã xảy ra lỗi", "errCode: " + res.data.data.errCode + "\nerrMsg: " + res.data.data.errMsg, "error");
                        setLoad(false);
                    }
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const handleChange = (e) => {
        setLoading(true);
        setSanh(e.target.value);
        axios.post(`${process.env.REACT_APP_API_URL}/casino/balance`, { provider: e.target.value }).then((res) => {
            if (res.data.data.errMsg.toUpperCase() == "SUCCESS") {
                setBalance(res.data.data.balance);
                setLoading(false);
            } else {
                setBalance(0);
                setLoading(false);
                swal("Đã xảy ra lỗi", "errCode: " + res.data.data.errCode + "\nerrMsg: " + res.data.data.errMsg, "error").then(() => window.location.reload());
            }
        });
    };
    return (
        <>
            {loading === true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="recharge">
                {profile && (
                    <>
                        <div className="menu-recharge">
                            {/*<div className={`menu-item-recharge ${activeOption == 0 ? "active" : ""}`} onClick={() => handleOptionClick(0)}>
                                <img src={require("../../../image/deposit.png")} />
                                <span>Nạp ví chính vào ví game</span>
                            </div>*/}
                            <div className={`menu-item-recharge ${activeOption == 1 ? "active" : ""}`} onClick={() => handleOptionClick(1)}>
                                <img src={require("../../../image/atm.png")} />
                                <span>Rút ví game sang ví chính</span>
                            </div>
                        </div>
                        <div className="content-recharge">
                            {activeOption == 0 && (
                                <>
                                    <div className="top_title">
                                        <div className="txt">Chuyển tiền từ ví chính vào ví game</div>
                                    </div>
                                </>
                            )}
                            {activeOption == 1 && (
                                <>
                                    <div className="top_title">
                                        <div className="txt">Chuyển tiền từ ví game sang ví chính</div>
                                    </div>
                                </>
                            )}
                            {profile && (
                                <>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="content_bank">
                                            <h2>
                                                Số dư ví chính: <b>{profile?.money?.toLocaleString('vi-VN')}</b>
                                            </h2>
                                            <select onChange={handleChange} style={{ padding: "5px 10px", fontSize: "15px", borderColor: "#bdbdbd", marginRight: "10px" }}>
                                                <option hidden>Chọn sảnh</option>
                                                <option value="IB">Sabah</option>
                                                <option value="JJ">Jili</option>
                                                <option value="AG">AG</option>
                                                <option value="WC">WM</option>
                                                <option value="V8">V8</option>
                                                <option value="DG">DG</option>
                                                <option value="EG">EG</option>
                                                <option value="AT">Allbet</option>
                                                <option value="TP">TP</option>
                                                <option value="S2">Sexy casino</option>
                                                <option value="KY">KY</option>
                                                <option value="MG">MG</option>
                                                <option value="GB">BBIN</option>
                                                <option value="SO">SBO</option>
                                                <option value="UG">UG</option>
                                                <option value="CM">CMD</option>
                                            </select>
                                            {sanh && (
                                                <h2 style={{ display: "inline-block" }}>
                                                    Số dư: <span>{balance?.toLocaleString('vi-VN')}</span>
                                                </h2>
                                            )}
                                        </div>
                                        <div>
                                            <div className="input">
                                                <label>Nhập số tiền</label>
                                                <input
                                                    className="ipadd"
                                                    type="text"
                                                    value={newMoney}
                                                    {...register("money")}
                                                    onClick={() => setNewMoney(null)}
                                                    onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString('vi-VN'))}
                                                />
                                                <button type="submit" className="btn-submit" style={{ marginLeft: "10px" }}>
                                                    Xác nhận
                                                </button>
                                            </div>
                                            {/*activeOption == 0 && (
                                                <>
                                                    <div className="top_title2">
                                                        <div className="txt">
                                                            Số tiền nhận được vào ví game:{" "}
                                                            {newMoney ? (Number(newMoney.replaceAll(".", "").replaceAll(",", "")) / 1000).toLocaleString('vi-VN') : 0}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {activeOption == 1 && (
                                                <>
                                                    <div className="top_title2">
                                                        <div className="txt">
                                                            Số tiền nhận được vào ví chính:{" "}
                                                            {newMoney ? (Number(newMoney.replaceAll(".", "").replaceAll(",", "")) * 1000).toLocaleString('vi-VN') : 0}
                                                        </div>
                                                    </div>
                                                </>
                                            )*/}
                                            <div style={{ display: "none" }}>
                                                <input className="ipadd" type="text" {...register("detail")} placeholder="Nhập mã giao dịch ngân hàng" defaultValue="Nạp" />
                                            </div>
                                        </div>
                                    </form>

                                    <div className="huongdan">
                                        <div className="title">Hướng dẫn</div>
                                        <ul>
                                            <li>Tỉ lệ quy đổi điểm: 1.000 điểm ví game = 1.000.000 điểm ví chính</li>
                                            <li>Vui lòng quy đổi điểm là số tiền chẵn (ví dụ 100.000), không nhập số lẻ (ví dụ 100.050)</li>
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
export default Transfer;
